#display-shops{
    /* border: 2px solid blue; */
}
#shops-container{
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 40px 0px;

    @media (max-width: 450px) {
        width: 100%;
        height: auto;
    }
}
#shops-container h2{
    font-size: xx-large;
    padding: 10px 0px;
}
#shops-container p{
    text-align: center;
    font-size: large;
    padding-bottom: 20px;
}
#shopContainer{
    border: 2px solid grey;
    padding: 13px;
    border-radius: 10px;
}
#shop-tile-container{
    border: 1px grey solid;
    border-radius: 4px;
    min-width: 250px;
}
#shop-tile-banner img{
    width:100%;
}

.shop-description {
    text-align: center;
}
