.css-1lekzkb {
    padding-bottom: 8px !important;
}

.col-lg-2-col-md-2{
    margin-left: 125px;
}

.productListContainer{
    margin:0 327px;
    margin-top: 63px;
}
.exclusive-product-details::hover{
cursor: pointer;
}

.exclusive-product-details{
    margin:10px 1px;
}

.exclusive-product-card-padding{
    padding: 0px 10px;
    text-overflow: ellipsis;
}
.exclusive-product-image{
    padding:0px;
    position: relative;
}

.product--text {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 95%;
    overflow: hidden;
    text-align: left;
}

.product--text__theme_blue {
    color: #6CB4EE;
}
.product--text__theme_maroon {
    color: #8E357A;
}

.product--text__theme_black {
    color: #F9F7F5;
}

.product--text__bold {
    font-weight: 700
}

.product--salesprice__margin {
    margin-top: 0.5rem;
}

.product--card__size__brand {
    display: flex;
    margin: 0.6rem 0;
    font-size: 13px;
}

.product---card__size__val {
    margin-left: 5px
}

.product--card__divider {
    margin: 0 .6rem;
    color: #E6E2DF;
    display: flex;
    align-items: center;
}

.product--card__username__av {
    display: flex;
    align-items: center;
    margin: 0.8rem 0;
}

.avatar__text {
    margin-left: .6rem;
    font-size: 14px;
}
.disableCard {
    pointer-events: none;
    opacity: 0.7;
}
.soldOut {
    font-weight: bold;
    font-size: 1.25rem;
    color: white;
    background: gray;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
}
.product--link{
    width: 100%;
    overflow:hidden;
    margin-top: 5%;
}
.exclusive-product-cart--footer__seller{
    display: flex; 
    justify-content:space-around;
    padding-bottom: 7px;
}
.exclusive-product-cart--footer__editIcon{
    width: 16px; 
    color: #EF5051
}