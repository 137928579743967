#UserShopContainer {
    margin: auto;
    margin-bottom: 30px;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 16px 0px !important;
}

.css-v0cfbd-MuiGrid-root {
    margin-left: 0px !important;
}

#UserShopContainer p {
    text-align: center;
}

#banner {
    height: 300px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    /* , url("assets2/images/shop-banner-yellow.png") */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.bannerImg{
    width: 100%;
    height: 300px;
}

div#shop-info {
    padding: 30px 150px;
}

#shop-info p {
    color: black;
    font-size: 14pt;
    margin: auto;

}

.ellipseCss{
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#shop-name {
    position: relative;
    bottom: 250px;
    text-align: center;
    width: 300px;
    left: 40%;
    color: white;
    border: 2px solid white;
    border-radius: 30px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.4);
    font-size: 22pt;
    font-weight: 700;
}

#UserShopContainer.css-15j6ooq-MuiGrid-root {
    flex-basis: 90% !important;
    max-width: 90% !important;
}

#edit-button button {
    background-color: #fff;
    margin-right: 20px;
}

/* edit button styling */
#edit-button button p {
    font-size: 14pt;
    padding-top: 10px;
    /* left: calc(100% - 121px); */

}

#edit-button button img {
    /* background-color: purple; */
    /* display: flex; */
    height: 40px;
}

/* edit modal styling */
.upload-button {
    color: white;
    background-color: #0066FF;
    font-size: 10pt;
    border: 2px solid #0066FF;
    border-radius: 5px;
}

#modal-modal-title {
    margin-bottom: 10px;
}


.profilePic{
    border: 1px solid #000;
    border-radius: 50px;
    width: 104px;
    height: 104px;
}
.Crop-Controls {
    margin-bottom: 10px;
}

.Crop-Controls>* {
    margin-bottom: 3px;
}

/* large screens */
@media (min-width: 1500px) {
    #UserShopContainer.css-un48xx-MuiGrid-root {
        width: 400% !important;
    }
}

@media (min-width: 1200px) {
    .css-7tryzw-MuiGrid-root {
        flex-basis: 300px !important;
    }

    .css-15j6ooq-MuiGrid-root {
        flex-basis: 100px !important;
        max-width: 100% !important;
    }

    /* #edit-button button{
        left: 1450px;
    } */

}

/* mobile */
@media (max-width: 500px) {
    #UserShopContainer {
        width: 100% !important;
    }

    #shop-name p {
        font-size: 21px !important;
    }

    div#shop-info {
        padding: 30px 50px;
    }

    .css-15j6ooq-MuiGrid-root {
        margin: auto !important;
        flex-basis: 70% !important;
    }


}